import React from 'react'
import NBLogo from '../../assets/NexusBrain.png'
import { BiErrorAlt } from 'react-icons/bi'

export function RestrictedAccount() {
    const handleRedirection = () => {
        localStorage.clear()
        window.location.replace(process.env.REACT_APP_NEXUSBRAIN_WEB_URL)
    }

    return (
        <div className="w-screen h-screen flex overflow-hidden md:flex-col sm:flex-col">
            <div className=" absolute w-full h-full bg-error bg-[length:600px_300px] bg-center"></div>
            <div className="w-7/12 h-full bg-main flex justify-center items-center md:w-full sm:w-full">
                <div className="text-white w-full pl-28 flex flex-col gap-8 md:pl-0 sm:pl-0 md:justify-center md:items-center sm:justify-center sm:items-center">
                    <div className=" md:flex-col md:justify-center md:items-center  sm:flex-col sm:justify-center sm:items-center">
                        <img
                            src={NBLogo}
                            alt="NexusBrain Logo"
                            className="brightness-200 w-16 md:pl-8 sm:pl-8 "
                        />
                        <p className="text-xl">NexusBrain</p>
                    </div>
                    <div className="w-full md:w-fit sm:w-fit">
                        <p className="text-4xl">Error 401</p>
                        <p className="text-5xl font-extrabold">Unauthorized Access</p>
                    </div>
                </div>
            </div>

            <div className="w-5/12 h-full bg-[#eeffec] md:w-full sm:w-full items-center justify-center">
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <BiErrorAlt className="text-9xl text-main" />
                    <p className="text-5xl mt-2 text-[#7c7c7c] font-extrabold">OOPS!</p>
                    <p className="text-xl text-[#7c7c7c]">
                        Permission Denied. Please contact your admin.
                    </p>
                    <button
                        onClick={handleRedirection}
                        className=" p-3 px-4 z-[9999] hover:shadow-md hover:shadow-main rounded bg-main text-white text-xl mt-5"
                    >
                        Go to <span className="font-bold">NEXUSBRAiN WEB</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
