import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { LoadingComponent } from 'components/LoadingComponent'
import { NoData } from 'components/NoData'

function EventsChat({
    arrayRes,
    kiqsSelected,
    eventQuestions,
    handleQuestionSelect,
    selectedQuestion,
    questionResponses,
}) {
    const [selectedImage, setSelectedImage] = useState()

    const [showResponse, setShowResponse] = useState(false)
    const [showReply, setShowReply] = useState({})

    const toggleResponse = () => setShowResponse(!showResponse)
    const toggleReply = (replyIndex) =>
        setShowReply((prev) => ({
            ...prev,
            [replyIndex]: !prev[replyIndex],
        }))
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        // <div className=" w-3/4 bg-white min-h-full h-[100%] overflow-y-auto border-l border-opacity-50 border-gray-400 grow">

        <div className="flex flex-row justify-between sm:flex-col sm:gap-3">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            <div className="w-1/3 h-full max-h-96 border-r-4 sm:border-none overflow-y-auto sm:w-full p-2">
                <p className="font-bold">Questions</p>
                {arrayRes[1].isLoading ? (
                    <span>
                        <LoadingComponent title="Questions" />
                    </span>
                ) : (
                    kiqsSelected === 'Chatroom' &&
                    eventQuestions?.chatroom?.length > 0 &&
                    eventQuestions?.chatroom?.map((question, questionIndex) => {
                        return (
                            <li
                                onClick={() => handleQuestionSelect(question)}
                                className={`${
                                    selectedQuestion.question === question.question &&
                                    'bg-main text-white'
                                } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                                key={questionIndex}
                            >
                                {question.question}
                            </li>
                        )
                    })
                )}

                {kiqsSelected === 'My KIQS' &&
                    eventQuestions.mykiqs &&
                    eventQuestions.mykiqs.map((question, questionIndex) => (
                        <li
                            onClick={() => handleQuestionSelect(question)}
                            className={`${
                                selectedQuestion.question === question.question &&
                                'bg-main text-white'
                            } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                            key={questionIndex}
                        >
                            {question.question}
                        </li>
                    ))}

                {kiqsSelected === 'SnapIntel' &&
                    eventQuestions.snapintel &&
                    eventQuestions.snapintel.slice(0, 7).map((question, questionIndex) => (
                        <li
                            onClick={() => handleQuestionSelect(question)}
                            className={`${
                                selectedQuestion.question === question.question &&
                                'bg-main text-white'
                            } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                            key={questionIndex}
                        >
                            {question.question}
                        </li>
                    ))}
            </div>
            <div className="w-1/3 border-r-4 h-full max-h-96 sm:border-r-0 sm:border-b sm:w-full overflow-y-auto p-2">
                <p className="font-bold pl-2">Responses</p>
                {arrayRes[2].isLoading ? (
                    <span>
                        <LoadingComponent title="Responses" />
                    </span>
                ) : questionResponses && questionResponses?.length == 0 ? (
                    <div className="w-full flex justify-center">
                        <div className="w-1/2 object-contain">
                            <NoData />
                        </div>
                    </div>
                ) : (
                    questionResponses &&
                    questionResponses.map((response, responseIndex) => (
                        <>
                            {response?.answer?.length > 0 && (
                                <>
                                    <div
                                        className="text-sm border-b p-2 border-l-4 border-l-main mt-4 mb-2 cursor-default"
                                        key={responseIndex}
                                    >
                                        <span className="text-sm font-bold pl-4 text-main">
                                            {response?.user?.fullname}
                                        </span>
                                        <div className="px-4">
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {response.status === 0 ? (
                                                    'Message is deleted by User'
                                                ) : response.status === 10 ? (
                                                    'Message is deleted by Company Admin'
                                                ) : response.status === 20 ? (
                                                    'Message is deleted by Super Admin'
                                                ) : response.status === 2 ||
                                                  response.status === 11 ||
                                                  response.status === 21 ? (
                                                    <div className="flex flex-col">
                                                        {!showResponse && (
                                                            <span>Message is hidden</span>
                                                        )}
                                                        <button
                                                            onClick={toggleResponse}
                                                            className=""
                                                        >
                                                            {showResponse ? (
                                                                <p className=" text-main text-left underline">
                                                                    Hide
                                                                </p>
                                                            ) : (
                                                                <p className=" text-main underline text-left">
                                                                    View
                                                                </p>
                                                            )}
                                                        </button>
                                                        {showResponse &&
                                                            response.answer &&
                                                            makeUrlsClickable(response?.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </div>
                                                ) : (
                                                    response.answer &&
                                                    makeUrlsClickable(response?.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )
                                                )}
                                            </p>
                                            <p className="text-xs">
                                                Date Created: {response.created_at}
                                            </p>
                                        </div>
                                    </div>

                                    {response.responses?.length > 0 &&
                                        response.responses.map((reply, replyIndex) => (
                                            <div
                                                className="w-3/4 ml-5 text-sm border-b py-1 border-l-4 border-l-main mb-1 cursor-default"
                                                key={replyIndex}
                                            >
                                                <span className="text-xs font-bold pl-4 text-main">
                                                    <span>Reply of : </span>
                                                    {reply?.user?.fullname}
                                                </span>
                                                <div className="px-4 ">
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {reply.status === 0 ? (
                                                            'Message is deleted by user'
                                                        ) : reply.status === 10 ? (
                                                            'Message is deleted by company admin'
                                                        ) : reply.status === 20 ? (
                                                            'Message is deleted by super admin'
                                                        ) : reply.status === 2 ||
                                                          reply.status === 11 ||
                                                          reply.status === 21 ? (
                                                            <>
                                                                {!showReply[replyIndex] && (
                                                                    <span>Message is hidden</span>
                                                                )}
                                                                <button
                                                                    onClick={() =>
                                                                        toggleReply(replyIndex)
                                                                    }
                                                                    className="ml-2"
                                                                >
                                                                    {showResponse ? (
                                                                        <p className=" text-main text-left underline">
                                                                            Hide
                                                                        </p>
                                                                    ) : (
                                                                        <p className=" text-main underline text-left">
                                                                            View
                                                                        </p>
                                                                    )}
                                                                </button>
                                                                {showReply[replyIndex] &&
                                                                    reply.answer &&
                                                                    makeUrlsClickable(
                                                                        reply?.answer
                                                                    ).map((part, index) => (
                                                                        <p
                                                                            className="leading-4"
                                                                            key={index}
                                                                        >
                                                                            {part}
                                                                        </p>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            reply.answer &&
                                                            makeUrlsClickable(reply?.answer).map(
                                                                (part, index) => (
                                                                    <p
                                                                        className="leading-4"
                                                                        key={index}
                                                                    >
                                                                        {part}
                                                                    </p>
                                                                )
                                                            )
                                                        )}
                                                    </p>
                                                    <p className="text-xs pt-1">
                                                        Date Created: {reply.user.created_at}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}
                        </>
                    ))
                )}
            </div>
            <div className="w-1/3 sm:w-full h-full max-h-96 overflow-y-auto p-2">
                <p className="font-bold pl-2">Media and Files</p>
                {arrayRes[2].isLoading ? (
                    <span>
                        <LoadingComponent title="Media and Files" />
                    </span>
                ) : questionResponses && questionResponses?.length == 0 ? (
                    <div className="w-full flex justify-center">
                        <div className="w-1/2 object-contain">
                            <NoData />
                        </div>
                    </div>
                ) : (
                    questionResponses &&
                    questionResponses.map(
                        (response, responseIndex) =>
                            response.attachments &&
                            response.attachments?.length > 0 && (
                                <>
                                    <div
                                        key={responseIndex}
                                        className="flex flex-col gap-2 border-b p-2"
                                    >
                                        <span className="text-sm font-bold pl-4 text-main">
                                            {response?.user?.fullname}
                                        </span>

                                        {response.attachments.map((attachment, attachIndex) => (
                                            <div className="flex" key={attachIndex}>
                                                {attachment.type === 'image' ? (
                                                    <img
                                                        key={attachment.id}
                                                        src={attachment.filename}
                                                        alt="Attachment"
                                                        className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                        onClick={() =>
                                                            setSelectedImage(attachment.filename)
                                                        }
                                                    />
                                                ) : attachment.type === 'file' ? (
                                                    <NavLink
                                                        key={attachIndex}
                                                        to={attachment.filename}
                                                        target="__blank"
                                                        className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                    >
                                                        <img
                                                            className="w-8"
                                                            src={PDFIcon}
                                                            alt="TEXT_FILE"
                                                        />
                                                        <p className="text-left text-xs break-all truncate-multiline-2">
                                                            {attachment.filename}
                                                        </p>
                                                    </NavLink>
                                                ) : null}
                                            </div>
                                        ))}

                                        <p className="text-xs">
                                            Date Created: {response.created_at}
                                        </p>

                                        {response.status === 0 ? (
                                            'Message is deleted by user'
                                        ) : response.status === 10 ? (
                                            'Message is deleted by company admin'
                                        ) : response.status === 20 ? (
                                            'Message is deleted by super admin'
                                        ) : response.status === 2 ||
                                          response.status === 11 ||
                                          response.status === 21 ? (
                                            <div className="flex flex-col">
                                                {!showResponse && <span>Attachment is hidden</span>}
                                                <button onClick={toggleResponse} className="ml-2">
                                                    {showResponse ? (
                                                        <p className=" text-main text-left underline">
                                                            Hide
                                                        </p>
                                                    ) : (
                                                        <p className=" text-main underline text-left">
                                                            View
                                                        </p>
                                                    )}
                                                </button>
                                                {showResponse && (
                                                    <div>
                                                        {response.attachments.map(
                                                            (attachment, attachIndex) => (
                                                                <div
                                                                    className="flex"
                                                                    key={attachIndex}
                                                                >
                                                                    {attachment.type === 'image' ? (
                                                                        <img
                                                                            key={attachment.id}
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            alt="Attachment"
                                                                            className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                                            onClick={() =>
                                                                                setSelectedImage(
                                                                                    attachment.filename
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : attachment.type ===
                                                                      'file' ? (
                                                                        <NavLink
                                                                            key={attachIndex}
                                                                            to={attachment.filename}
                                                                            target="__blank"
                                                                            className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                        >
                                                                            <img
                                                                                className="w-8"
                                                                                src={PDFIcon}
                                                                                alt="TEXT_FILE"
                                                                            />
                                                                            <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                {
                                                                                    attachment.filename
                                                                                }
                                                                            </p>
                                                                        </NavLink>
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>

                                    {response.responses?.length > 0 &&
                                        response.responses.map((reply, replyIndex) => (
                                            <div
                                                className="w-3/4 ml-5 text-sm border-b py-1 border-l-4 border-l-main mb-1 cursor-default"
                                                key={replyIndex}
                                            >
                                                <span className="text-xs font-bold pl-4 text-main">
                                                    <span>Reply of : </span>
                                                    {reply?.user?.fullname}
                                                </span>

                                                {reply.attachments.map(
                                                    (attachment, attachIndex) => (
                                                        <div
                                                            className="flex pl-4 pb-2"
                                                            key={attachIndex}
                                                        >
                                                            {attachment.type === 'image' ? (
                                                                <img
                                                                    key={attachment.id}
                                                                    src={attachment.filename}
                                                                    alt="Attachment"
                                                                    className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                                    onClick={() =>
                                                                        setSelectedImage(
                                                                            attachment.filename
                                                                        )
                                                                    }
                                                                />
                                                            ) : attachment.type === 'file' ? (
                                                                <NavLink
                                                                    key={attachIndex}
                                                                    to={attachment.filename}
                                                                    target="__blank"
                                                                    className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                >
                                                                    <img
                                                                        className="w-8"
                                                                        src={PDFIcon}
                                                                        alt="TEXT_FILE"
                                                                    />
                                                                    <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                        {attachment.filename}
                                                                    </p>
                                                                </NavLink>
                                                            ) : null}
                                                        </div>
                                                    )
                                                )}

                                                <p className="text-xs">
                                                    Date Created: {reply.user.created_at}
                                                </p>

                                                {reply.status === 0 ? (
                                                    'Message is deleted by user'
                                                ) : reply.status === 10 ? (
                                                    'Message is deleted by company admin'
                                                ) : reply.status === 20 ? (
                                                    'Message is deleted by super admin'
                                                ) : reply.status === 2 ||
                                                  reply.status === 11 ||
                                                  reply.status === 21 ? (
                                                    <div className="flex flex-col">
                                                        {!showReply[replyIndex] && (
                                                            <span>Attachment is hidden</span>
                                                        )}
                                                        <button
                                                            onClick={() => toggleReply(replyIndex)}
                                                            className="ml-2"
                                                        >
                                                            {showResponse ? (
                                                                <p className=" text-main text-left underline">
                                                                    Hide
                                                                </p>
                                                            ) : (
                                                                <p className=" text-main underline text-left">
                                                                    View
                                                                </p>
                                                            )}
                                                        </button>
                                                        {showReply[replyIndex] && (
                                                            <div>
                                                                {reply.attachments.map(
                                                                    (attachment, attachIndex) => (
                                                                        <div
                                                                            className="flex"
                                                                            key={attachIndex}
                                                                        >
                                                                            {attachment.type ===
                                                                            'image' ? (
                                                                                <img
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    alt="Attachment"
                                                                                    className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                                                    onClick={() =>
                                                                                        setSelectedImage(
                                                                                            attachment.filename
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : attachment.type ===
                                                                              'file' ? (
                                                                                <NavLink
                                                                                    key={
                                                                                        attachIndex
                                                                                    }
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                    className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                >
                                                                                    <img
                                                                                        className="w-8"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                        alt="TEXT_FILE"
                                                                                    />
                                                                                    <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                        {
                                                                                            attachment.filename
                                                                                        }
                                                                                    </p>
                                                                                </NavLink>
                                                                            ) : null}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))}
                                </>
                            )
                    )
                )}
            </div>
        </div>
        // </div>
    )
}

export default EventsChat
