import { Fragment } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'

export const ChatMessage = ({
    chat,
    showChat,
    toggleChat,
    setSelectedImage,
    makeUrlsClickable,
}) => {
    const renderMessageStatus = () => {
        switch (chat?.status) {
            case 0:
                return 'Message is deleted by User'
            case 10:
                return 'Message is deleted by Company Admin'
            case 20:
                return 'Message is deleted by Super Admin'
            default:
                return null
        }
    }

    const renderMessage = () => {
        if (chat?.status === 2 || chat?.status === 11 || chat?.status === 21) {
            return (
                <>
                    {!showChat[chat.id] && <span>Message is hidden</span>}
                    <button onClick={() => toggleChat(chat.id)} className="ml-2">
                        <p className="text-main underline text-left">
                            {showChat[chat.id] ? 'Hide' : 'View'}
                        </p>
                    </button>
                    {showChat[chat.id] && renderChatDetails()}
                </>
            )
        }
        return renderChatDetails()
    }

    const renderChatDetails = () => (
        <>
            <p className="text-sm text-justify break-words mb-2" style={{ whiteSpace: 'pre-line' }}>
                {chat.answer &&
                    makeUrlsClickable(chat.answer).map((part, index) => (
                        <Fragment key={index}>{part}</Fragment>
                    ))}
            </p>
            {renderAttachments()}
            <div className="w-full flex gap-4 mt-2 justify-between">
                <div className="flex gap-2">
                    <div className="flex">
                        <span className="text-main">
                            <BiSolidLike />
                        </span>
                        <span>{chat.total_likes}</span>
                    </div>
                    <div className="flex">
                        <span className="text-grey">
                            <BiSolidDislike />
                        </span>
                        <span>{chat.total_dislikes}</span>
                    </div>
                </div>
                <div>
                    <span>{chat.created_at}</span>
                </div>
            </div>
        </>
    )

    const renderAttachments = () => {
        if (!chat?.attachments || chat.attachments?.length === 0) return null

        const images = chat.attachments.filter((attachment) => attachment.type === 'image')
        const nonImages = chat.attachments.filter((attachment) => attachment.type !== 'image')

        return (
            <>
                {images?.length > 0 &&
                    (images?.length > 1 ? (
                        <div className="grid grid-cols-4 gap-1">
                            {images.map((attachment) => (
                                <ImageAttachment
                                    key={attachment.id}
                                    attachment={attachment}
                                    setSelectedImage={setSelectedImage}
                                />
                            ))}
                        </div>
                    ) : (
                        images.map((attachment) => (
                            <ImageAttachment
                                key={attachment.id}
                                attachment={attachment}
                                setSelectedImage={setSelectedImage}
                            />
                        ))
                    ))}
                <div>
                    {nonImages.map((attachment) => (
                        <NonImageAttachment key={attachment.id} attachment={attachment} />
                    ))}
                </div>
            </>
        )
    }

    return <div className="p-1">{renderMessageStatus() || renderMessage()}</div>
}

const ImageAttachment = ({ attachment, setSelectedImage }) => (
    <div className="flex items-start justify-start w-full">
        <img
            className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
            src={attachment.filename}
            alt="Attachment"
            onClick={() => setSelectedImage(attachment.filename)}
        />
    </div>
)

const NonImageAttachment = ({ attachment }) => (
    <div className="flex items-start justify-start w-full">
        {attachment.type === 'file' && (
            <NavLink
                to={attachment.filename}
                target="__blank"
                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
            >
                <img className="w-8" src={PDFIcon} alt="TEXT_FILE" />
                <p className="text-left text-xs break-all truncate-multiline-2">
                    {attachment.filename}
                </p>
            </NavLink>
        )}
    </div>
)
