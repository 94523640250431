import React, { useState } from 'react'

import PDFIcon from '../../assets/pdf.png'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { ConvertLinksToClickable } from 'components/Responses/ClickableResponses'

function ResponsesChatroom({ dataContainer }) {
    const [collapse, setCollapse] = useState(true)

    return (
        <div className="w-full flex flex-col ">
            <div className="w-full bg-main py-2 px-2 flex justify-between items-center rounded-t-md">
                <p className="font-semibold text-white">
                    {dataContainer.type === 0
                        ? 'Chatroom KIQs'
                        : dataContainer.type === 1
                        ? 'My KIQS'
                        : 'SnapIntel'}
                </p>
            </div>
            <div className="w-full h-full max-h-[350px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 py-5 rounded-b-md">
                {!dataContainer.question ? (
                    <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm font-bold">
                        GENERAL DISCUSSION
                    </div>
                ) : (
                    <div className="flex w-full items-center gap-2 px-2 ">
                        <div className="bg-main text-center aspect-square rounded-full pt-1 w-8 h-8 text-white">
                            Q
                        </div>
                        <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm">
                            {dataContainer.question.question}
                        </div>
                    </div>
                )}

                <div className="w-full flex flex-col gap-2">
                    <div
                        onClick={() => setCollapse(!collapse)}
                        className="cursor-pointer pl-5 flex gap-2 items-center text-xs hover:text-[14px]"
                    >
                        <span
                            className="rotate-90 text-md -mt-2 cursor-pointer"
                            onClick={() => setCollapse(!collapse)}
                        >
                            <MdOutlineSubdirectoryArrowLeft />
                        </span>
                        <span className=" ">{collapse ? 'Hide' : 'Show'} Responses</span>
                    </div>
                    {collapse && (
                        <React.Fragment>
                            <div className="flex flex-col w-full items-end justify-end gap-2 ml-5 px-6 animate_fade_up">
                                <div className="flex flex-col gap-2 w-3/4 items-end justify-end">
                                    <React.Fragment>
                                        <p className="text-xs font-bold">
                                            {dataContainer?.user?.fullname}
                                        </p>

                                        <div className="text-xs flex flex-col w-fit justify-end items-end text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                            <p className="break-all whitespace-break-spaces">
                                                <ConvertLinksToClickable
                                                    text={dataContainer?.answer}
                                                />{' '}
                                            </p>

                                            {dataContainer.attachments &&
                                                dataContainer.attachments?.length > 0 &&
                                                dataContainer.attachments[0].type === 'image' && (
                                                    <img
                                                        src={dataContainer.attachments[0].filename}
                                                        alt="Attachment"
                                                        className="h-[200px] mt-5"
                                                    />
                                                )}
                                            {dataContainer.type === 0 && (
                                                <div className="flex w-full gap-4 pt-2 justify-between items-center">
                                                    <div className="flex gap-4">
                                                        <p className="flex gap-1">
                                                            <span className="text-main">
                                                                <BiSolidLike />
                                                            </span>
                                                            <span>{dataContainer.total_likes}</span>
                                                        </p>
                                                        <p className="flex gap-1">
                                                            <span className="text-grey">
                                                                <BiSolidDislike />
                                                            </span>
                                                            <span>
                                                                {dataContainer.total_dislikes}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <span className="text-xs font-bold text-grey">
                                                        <span>Date: </span>{' '}
                                                        {dataContainer.created_at}
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex ">
                                            <span className="flex flex-col gap-2">
                                                {dataContainer.responses &&
                                                    dataContainer.responses?.length > 0 &&
                                                    dataContainer.responses.map((responses) => (
                                                        <div
                                                            className="flex w-full items-center justify-end "
                                                            key={responses.id}
                                                        >
                                                            <span className="rotate-90 mr-10 text-xl">
                                                                {/* <MdOutlineSubdirectoryArrowLeft /> */}
                                                            </span>
                                                            <span className="text-xs gap-2 flex flex-col w-fit justify-end items-end text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                                <span className="text-xs font-bold">
                                                                    {responses?.user?.fullname}
                                                                </span>
                                                                <p className="break-all whitespace-break-spaces">
                                                                    <ConvertLinksToClickable
                                                                        text={responses?.answer}
                                                                    />
                                                                </p>

                                                                {responses.attachments &&
                                                                    responses.attachments?.length >
                                                                        0 && (
                                                                        <React.Fragment>
                                                                            {responses.attachments.map(
                                                                                (attachment) => (
                                                                                    <div
                                                                                        className="flex items-end justify-end w-2/3"
                                                                                        key={
                                                                                            attachment.id
                                                                                        }
                                                                                    >
                                                                                        {attachment.type ===
                                                                                        'image' ? (
                                                                                            <img
                                                                                                src={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                alt="Attachment"
                                                                                                // className="h-[150px]"
                                                                                            />
                                                                                        ) : attachment.type ===
                                                                                          'file' ? (
                                                                                            <NavLink
                                                                                                to={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                target="__blank"
                                                                                                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                            >
                                                                                                <img
                                                                                                    className="w-8"
                                                                                                    src={
                                                                                                        PDFIcon
                                                                                                    }
                                                                                                    alt="TEXT_FILE"
                                                                                                />
                                                                                                <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                                    {
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                </p>
                                                                                            </NavLink>
                                                                                        ) : null}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                {dataContainer.type === 0 && (
                                                                    <div className="flex w-full gap-4 pt-2 justify-between items-center">
                                                                        <div className="flex gap-4">
                                                                            <p className="flex gap-1">
                                                                                <span className="text-main">
                                                                                    <BiSolidLike />
                                                                                </span>
                                                                                <span>
                                                                                    {
                                                                                        responses.total_likes
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                            <p className="flex gap-1">
                                                                                <span className="text-grey">
                                                                                    <BiSolidDislike />
                                                                                </span>
                                                                                <span>
                                                                                    {
                                                                                        responses.total_dislikes
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <span className="text-xs font-bold text-grey">
                                                                            <span>Date: </span>{' '}
                                                                            {
                                                                                responses.user
                                                                                    .created_at
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        </div>
                                                    ))}
                                            </span>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ResponsesChatroom
