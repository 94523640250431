import React, { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Title } from '@tremor/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MainNav from 'components/MainNav'
import { useMutation, useQueries, useQuery } from 'react-query'
import { getSpacesEventsApi } from 'services/request/SpacesAndEvents'
import { LoadingComponent } from 'components/LoadingComponent'
import { getCompaniesQuestionApi, getCompaniesResponsesApi } from 'services/request/Companies'
import { getChatroomApi } from 'services/request/Dashboard'
import { BiChevronDown } from 'react-icons/bi'
import { FaFilter } from 'react-icons/fa'
import { Checkbox } from 'components/Checkbox'
import ImageView from 'components/ImageView'
import { IoCloseOutline } from 'react-icons/io5'
import {
    getEventDocxFile,
    getEventPdfFile,
    getExportEventsList,
    getExportSelectedCompany,
} from 'services/request/Exports'
import EventsChat from 'components/ChatSelection/EventsChat'
import GeneralDiscussion from 'components/ChatSelection/GeneralDiscussion'
import { MdOutlineFileDownload } from 'react-icons/md'
import { DownloadProgress } from 'components/DownloadingComponent'
import PdfPreview from 'components/Preview/PdfPreview'
import DocxDownload from 'components/Buttons/DocxDownload'
import DownloadPDF from 'components/Buttons/PdfDownloadButton'
import { AiModal } from 'components/AiModal/AiModal'
import { useAiSummaryContext } from 'store/context/AiSummaryContextProvider'
import Modal from 'components/AiModal/AiDisable'

function SpacesEvents() {
    const { data, isLoading } = useQuery('getSpacesandEvents', getSpacesEventsApi)
    const { result } = useMemo(() => data ?? [], [data])
    const [dataCon, setDataCon] = useState(null)
    const [dataEvent, setDataEvent] = useState(null)
    const [attachments, setAttachments] = useState()
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState(result)
    const [eventQuestions, setEventQuestions] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState(0)
    const [kiqsSelected, setKiqsSelected] = useState('General Discussion')
    const [typeSelected, setTypeSelected] = useState()
    const [questionResponses, setQuestionResponses] = useState([])
    const [kiqsView, setKiqsView] = useState(0)
    const [toggle, setToggle] = useState(false)
    const checkboxRef = useRef(null)
    const checkboxReff = useRef(null)

    const [eventSearchable, setEventSearchable] = useState(true)
    const [groupSearchable, setGroupSearchable] = useState(true)
    const [selectedButton, setSelectedButton] = useState(3)
    const [selectedImage, setSelectedImage] = useState()
    const [toggleFilter, setToggleFilter] = useState(false)
    const [toggleFilterDocx, setToggleFilterDocx] = useState(false)
    const [progress, setProgress] = useState()
    const [loading, setLoading] = useState(false)
    const { isAiEnabled } = useAiSummaryContext()

    const closeModal = () => {
        setQuestionResponses([])
        setDataEvent(null)
        setSelectedQuestion(0)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggleFilter(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const customStyles = {
        rows: {
            style: {
                width: 'fit', // override the row height
            },
        },
        headCells: {
            style: {
                width: 'fit', // override the row height
            },
        },
        cells: {
            style: {
                width: 'fit', // override the row height
            },
        },
    }

    const calculateTotalUsers = (row) =>
        row.groups.reduce((acc, group) => acc + (group.users ? group.users?.length : 0), 0)

    const handleSetDataEvent = (row) => {
        setDataEvent(row)
        // setKiqsSelected('')
    }
    const columns = [
        {
            name: 'Event Name',
            selector: (row) => (eventSearchable ? row.event_name : null),
            cell: (row) => (
                <button
                    className="cursor-pointer whitespace-nowrap hover:font-bold "
                    onClick={() => handleSetDataEvent(row)}
                >
                    {row.event_name}
                </button>
            ),
            // center: true,
            grow: 0,
            width: '340px',
            sortable: true,
        },
        {
            name: 'Agents',
            cell: (row) => {
                const totalUsers = calculateTotalUsers(row)
                return (
                    <button
                        disabled={totalUsers === 0}
                        className="cursor-pointer hover:font-bold bg-main bg-opacity-40 w-8 h-8 rounded-full justify-center text-center align-middle "
                        onClick={() => setDataCon(row)}
                    >
                        {totalUsers}
                    </button>
                )
            },
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
            sortFunction: (a, b, direction) => {
                const totalUsersA = calculateTotalUsers(a)
                const totalUsersB = calculateTotalUsers(b)
                return direction === 'asc' ? totalUsersA - totalUsersB : totalUsersB - totalUsersA
            },
        },
        {
            name: 'Responses',
            // selector: calculateTotalResponses,
            selector: (row) => row.chatroom_count + row.mykiqs_count + row.snapintel_count,
            cell: (row) => {
                const responsesCount = row.chatroom_count + row.mykiqs_count + row.snapintel_count
                return <span>{responsesCount}</span>
            },
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Attachments',
            selector: (row) => row.attachments?.length ?? 0,
            center: true,
            grow: 0,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Groups',
            selector: 'groups',
            cell: (row) => (
                <div className="flex gap-2 items-center my-2 sm:flex-col md:flex-col sm:justify-start sm:items-start">
                    {row.groups &&
                        row.groups.map((group, index) => (
                            <p
                                key={index}
                                className="px-2 py-1 border-[.6px] border-main bg-main bg-opacity-40 rounded-sm whitespace-nowrap "
                            >
                                {group.group_name}
                            </p>
                        ))}
                </div>
            ),
        },
    ]

    const handleSearchChange = (value) => {
        setSearch(value)

        const newFilteredData = result.filter((user) => {
            const isSearchMatch =
                value.trim() === '' ||
                columns.some((column) => {
                    if (typeof column.selector === 'function') {
                        const selectedValue = column.selector(user)
                        if (typeof selectedValue === 'string') {
                            return selectedValue.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                    return false
                })

            const isGroupSearchMatch = groupSearchable
                ? value.trim() === '' ||
                  (user.groups &&
                      user.groups
                          .map((group) => group.group_name.toLowerCase())
                          .some((groupName) => groupName.includes(value.toLowerCase())))
                : null

            return isSearchMatch || isGroupSearchMatch
        })

        setFilteredData(newFilteredData)
    }

    const { mutate: mutateExportAll, isLoading: mutateIsloading } = useMutation(() =>
        getExportEventsList()
    )

    const exportAllData = () => {
        mutateExportAll()
    }

    const arrayRes = useQueries([
        {
            queryKey: ['getChatroomApi', kiqsView, dataEvent],
            queryFn: () => getChatroomApi(dataEvent.id),
            onSuccess: (data) => setAttachments(data.attachments),
            enabled: kiqsView === 0 || kiqsView === 3,
        },
        {
            queryKey: ['getCompaniesQuestionApi', 2, dataEvent, kiqsSelected],
            queryFn: () => getCompaniesQuestionApi(dataEvent.id),
            onSuccess: (data) => {
                setEventQuestions(data.result)
            },
            enabled: !!dataEvent,
        },
        {
            queryKey: ['getCompaniesResponsesApiHello', 3, selectedQuestion],
            queryFn: () =>
                getCompaniesResponsesApi(selectedQuestion.id, dataEvent.id, typeSelected),
            onSuccess: (data) => setQuestionResponses(data.result),
            // enabled: selectedQuestion.length !== 0,
        },
    ])

    const handleKIQSClicks = (kiqs, type) => {
        setKiqsSelected(kiqs)
        setTypeSelected(type)
        setSelectedButton(type)
        setQuestionResponses([])
        setSelectedQuestion([])
    }

    const handleQuestionSelect = (question) => {
        setSelectedQuestion(question)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggle(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxReff.current && !checkboxReff.current.contains(event.target)) {
                setToggleFilterDocx(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleDownloadCSV = () => {
        setLoading(true)
        getExportSelectedCompany(
            '/api/v1/export_space_and_events',
            {
                event_id: dataEvent.id,
            },
            (data) => {
                setProgress(data.onDownloadProgress)
                setLoading(false)
            },
            `Spaces and Events KIQs ${dataEvent.event_name} .xlsx`
        )
    }
    const [pdfPreview, setPdfPreview] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedDownload, setSelectedDownload] = useState(null)
    const [modalDocx, setModalDocx] = useState(false)
    const [enableModal, setEnableModal] = useState(false)
    const [enableModalDocx, setEnableModalDocx] = useState(false)
    const [downloadConfig, setDownloadConfig] = useState(null)

    const openModalEnableDocx = (downloadConfig) => {
        setDownloadConfig(downloadConfig)
        setEnableModalDocx(true)
    }

    const handleModalConfirmDocx = () => {
        if (downloadConfig) {
            handleDownloadDocx(downloadConfig.id, downloadConfig.categories, 'desc', false)
        }
        setEnableModalDocx(false)
    }

    const openModalDocx = (downloadConfig) => {
        setSelectedDownload(downloadConfig)
        setModalDocx(true)
    }
    const confirmDownloadDocx = (isai, prompt) => {
        if (selectedDownload) {
            handleDownloadDocx(
                selectedDownload.id,
                selectedDownload.categories,
                prompt?.id,
                selectedDownload.sort,
                isai
            )
        }
        setModalDocx(false)
    }
    const handleDownloadDocx = async (eventId, type, prompt, order, isai) => {
        try {
            setLoading(true)

            await getEventDocxFile({
                event: eventId,
                intel_type: type,
                order_type: order,
                is_ai: isai,
                prompt_id: prompt,

                event_name: dataEvent.event_name,
            })

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading DOCX:', e)
            setLoading(false)
        }
    }

    const openModalEnable = (downloadConfig) => {
        setDownloadConfig(downloadConfig)
        setEnableModal(true)
    }

    const handleModalConfirm = () => {
        if (downloadConfig) {
            handleDownloadPDF(downloadConfig.id, downloadConfig.categories, 'desc', false)
        }
        setEnableModal(false)
    }

    const openModal = (downloadConfig) => {
        setSelectedDownload(downloadConfig)
        setIsModalOpen(true)
    }

    const confirmDownload = (isai, prompt) => {
        if (selectedDownload) {
            handleDownloadPDF(
                selectedDownload.id,
                selectedDownload.categories,
                selectedDownload.sort,
                prompt?.id,

                isai
            )
        }
        setIsModalOpen(false)
    }

    const handleDownloadPDF = async (eventId, type, order, prompt, isai) => {
        try {
            setLoading(true)

            await getEventPdfFile(
                {
                    event: eventId,
                    intel_type: type,
                    order_type: order,
                    is_ai: isai,
                    prompt_id: prompt,
                    event_name: dataEvent.event_name,
                },
                null,

                setPdfPreview
            )
            setLoading(false)
        } catch (e) {
            console.error('Error while downloading PDF:', e)
            setLoading(false)
        }
    }
    const handleClose = () => {
        setPdfPreview(null)
    }

    return (
        <div className=" flex flex-col w-screen h-fit overflow-x-hidden">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            {pdfPreview && (
                <PdfPreview
                    handleClose={handleClose}
                    dataEvent={dataEvent}
                    pdfPreview={pdfPreview}
                    loading={loading}
                />
            )}

            {enableModal && (
                <Modal
                    title="AI Unavailable"
                    message="AI functionality is currently unavailable. Would you like to continue without AI?"
                    onConfirm={handleModalConfirm}
                    onClose={() => setEnableModal(false)}
                />
            )}

            {enableModalDocx && (
                <Modal
                    title="AI Unavailable"
                    message="AI functionality is currently unavailable. Would you like to continue without AI?"
                    onConfirm={handleModalConfirmDocx}
                    onClose={() => setEnableModalDocx(false)}
                />
            )}

            {isModalOpen && (
                <AiModal setIsModalOpen={setIsModalOpen} confirmDownload={confirmDownload} />
            )}

            {modalDocx && (
                <AiModal setIsModalOpen={setModalDocx} confirmDownload={confirmDownloadDocx} />
            )}

            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex gap-5 py-5 mb-10">
                <div className="w-full h-fit rounded-lg custom-scroll flex flex-col gap-4">
                    <div className="w-full px-5">
                        <MainNav />
                    </div>

                    <div className="w-full h-fit flex justify-between px-5 items-center sm:flex-col">
                        <p className="font-bold text-2xl sm:pb-3">Events</p>
                        <div className="sm:flex-col sm:w-full flex w-2/4 items-center justify-center h-full gap-2">
                            <div className="w-full h-fit flex  items-center justify-center">
                                <input
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    type="text"
                                    value={search}
                                    className="border border-gray-400 rounded-md placeholder:text-sm pl-3 p-1 flex grow bg-white bg-opacity-60 outline-none"
                                    placeholder="Search..."
                                />

                                {search?.length > 0 && (
                                    <div
                                        onClick={() => handleSearchChange('')}
                                        className="w-fit px-4 py-2 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                    >
                                        <IoCloseOutline />
                                    </div>
                                )}
                            </div>
                            <span className="sm:flex-row flex gap-2">
                                <div className="flex flex-col justify-start items-end">
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        ref={checkboxRef}
                                        className="transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex border border-gray-400 rounded-md py-2 px-4 bg-white shadow-inner text-main bg-opacity-50"
                                    >
                                        {' '}
                                        <div className="flex">
                                            <FaFilter /> <BiChevronDown />
                                        </div>
                                    </div>
                                    {toggle && (
                                        <div className="animate_fade_up z-20 absolute mt-9 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col border border-gray-400 rounded-md h-fit pt-2 pb-4 px-4 bg-white shadow-inner text-main">
                                            <p className="text-sm">Filter by:</p>
                                            <Checkbox
                                                key="Events"
                                                name="Event"
                                                title="Event"
                                                isChecked={eventSearchable}
                                                setValue={() =>
                                                    setEventSearchable(!eventSearchable)
                                                }
                                            />
                                            <Checkbox
                                                key="Groups"
                                                name="Groups"
                                                title="Groups"
                                                isChecked={groupSearchable}
                                                setValue={() =>
                                                    setGroupSearchable(!groupSearchable)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>

                                <button
                                    className=" text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap"
                                    onClick={() => exportAllData()}
                                >
                                    <span className="text-sm flex items-center hover:scale-105 transition-all duration-300 cursor-pointer">
                                        CSV{' '}
                                        <span className="text-lg">
                                            <MdOutlineFileDownload />
                                        </span>
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div className="w-full h-fit custom-header px-4 flex gap-0 sm:flex-col space-x-4 sm:space-x-0 sm:space-y-4 rounded-md overflow-hidden">
                        <div className=" w-full grow rounded-md overflow-hidden">
                            <DataTable
                                progressComponent={<LoadingComponent title={'Events'} />}
                                progressPending={isLoading}
                                columns={columns}
                                data={filteredData ? filteredData : result}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="900px"
                                customStyles={customStyles}
                            />
                        </div>
                        {dataCon === null ? (
                            <div></div>
                        ) : (
                            <div className=" w-1/4 sm:w-full h-fit  custom-header bg-white p-4 rounded-lg shadow-md animate_fade_up pl-2">
                                <div className="w-full flex justify-between">
                                    <Title className="text-main font-bold ">
                                        {dataCon.event_name}
                                    </Title>
                                    <p
                                        className="text-xl items-center h-full text-main cursor-pointer"
                                        onClick={() => setDataCon(null)}
                                    >
                                        <AiOutlineCloseCircle />
                                    </p>
                                </div>
                                <div className="max-h-[400px] overflow-y-auto pl-2 ">
                                    <p className="font-bold">Members:</p>
                                    <ol>
                                        {dataCon.groups.map((group, groupIndex) => {
                                            return (
                                                <div key={groupIndex}>
                                                    <p className="font-semibold text-main">
                                                        &emsp; Group: {group.group_name}
                                                    </p>
                                                    {group.users &&
                                                        group.users.map((user, userIndex) => {
                                                            return (
                                                                <li key={userIndex}>
                                                                    {user.fullname}
                                                                </li>
                                                            )
                                                        })}
                                                </div>
                                            )
                                        })}
                                    </ol>
                                </div>
                            </div>
                        )}
                    </div>
                    {dataEvent && (
                        <div className=" fixed z-20 top-0 pt-14 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
                            <p
                                className="text-2xl opacity-70 z-50 hover:opacity-100 items-center fixed top-16 right-5  text-slate-500 cursor-pointer md:text-2xl sm:text-3xl"
                                onClick={() => closeModal()}
                            >
                                <AiOutlineCloseCircle />
                            </p>
                            <div className="bg-white p-7 w-[100%] h-[100%] rounded-md flex space-y-7 border shadow-lg border-main flex-col overflow-y-auto overflow-x-hidden">
                                <div className="flex flex-col gap-2">
                                    <div className="w-full flex justify-between gap-4 items-center  ">
                                        <div className="text-main font-bold text-3xl sm:text-lg w-full  justify-between flex">
                                            <p>{dataEvent?.event_name}</p>{' '}
                                            {loading ? (
                                                <DownloadProgress progress={progress} />
                                            ) : (
                                                <div className="flex justify-start items-center sm:gap-1 gap-3 sm:flex-col ">
                                                    <button
                                                        onClick={() =>
                                                            setToggleFilterDocx(!toggleFilterDocx)
                                                        }
                                                        ref={checkboxReff}
                                                        className="text-xs relative whitespace-nowrap flex items-center h-fit cursor-pointer py-2 my-2 sm:my-0"
                                                    >
                                                        DOCX
                                                        <span className="text-xl">
                                                            <MdOutlineFileDownload />
                                                        </span>
                                                        {toggleFilterDocx && (
                                                            <DocxDownload
                                                                handleDownloadDocx={
                                                                    handleDownloadDocx
                                                                }
                                                                isAiEnabled={isAiEnabled}
                                                                openModalDocx={openModalDocx}
                                                                openModalEnableDocx={
                                                                    openModalEnableDocx
                                                                }
                                                                dataEvent={dataEvent}
                                                            />
                                                        )}
                                                    </button>

                                                    <button
                                                        onClick={() =>
                                                            setToggleFilter(!toggleFilter)
                                                        }
                                                        ref={checkboxRef}
                                                        className="text-xs relative whitespace-nowrap flex items-center h-fit cursor-pointer py-2 px-4 my-2 sm:my-0"
                                                    >
                                                        PDF{' '}
                                                        <span className="text-xl">
                                                            <MdOutlineFileDownload />
                                                        </span>
                                                        {toggleFilter && (
                                                            <DownloadPDF
                                                                handleDownloadPDF={
                                                                    handleDownloadPDF
                                                                }
                                                                isAiEnabled={isAiEnabled}
                                                                openModal={openModal}
                                                                openModalEnable={openModalEnable}
                                                                dataEvent={dataEvent}
                                                            />
                                                        )}
                                                    </button>
                                                    <button
                                                        onClick={() => handleDownloadCSV()}
                                                        className="text-xs flex items-center hover:scale-105 transition-all duration-300 cursor-pointer"
                                                    >
                                                        CSV{' '}
                                                        <span className="text-xl">
                                                            <MdOutlineFileDownload />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <div className="w-full flex gap-2 sm:flex-col border-b-2 border-main border-opacity-30 pb-7">
                                        <button
                                            onClick={() =>
                                                handleKIQSClicks('General Discussion', 3)
                                            }
                                            className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm text-main border-main bg-white-25 ${
                                                selectedButton === 3 ? 'underline font-bold' : ''
                                            }`}
                                        >
                                            General Discussion
                                        </button>
                                        {eventQuestions.chatroom && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('Chatroom', 0)
                                                    setSelectedQuestion(eventQuestions?.chatroom[0])
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-[#7cc576] text-white hover:bg-[#87d680] ${
                                                    selectedButton === 0
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                Chatroom KIQs
                                            </button>
                                        )}

                                        {eventQuestions.mykiqs && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('My KIQS', 1)
                                                    setSelectedQuestion(eventQuestions?.mykiqs[0])
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-[#333333] text-white hover:bg-[#484848] ${
                                                    selectedButton === 1
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                My KIQs
                                            </button>
                                        )}

                                        {eventQuestions.snapintel && (
                                            <button
                                                onClick={() => {
                                                    handleKIQSClicks('SnapIntel', 2)
                                                    setSelectedQuestion(
                                                        eventQuestions?.snapintel[0]
                                                    )
                                                }}
                                                className={`w-1/3 sm:w-full text-base px-2 py-4 border rounded-sm bg-main bg-opacity-25 text-main hover:bg-[#e0f7de] ${
                                                    selectedButton === 2
                                                        ? 'underline font-bold'
                                                        : ''
                                                }`}
                                            >
                                                SnapIntel
                                            </button>
                                        )}
                                    </div>

                                    {kiqsSelected === 'General Discussion' ? (
                                        // <div className="animate_fade_up custom-scroll h-fit custom-pagination mt-3 w-full border  border-main/70 rounded-md block z-10">
                                        <div>
                                            <GeneralDiscussion
                                                dataContainer={arrayRes[0]?.data}
                                                isLoading={arrayRes[0]?.isLoading}
                                                className=" border  border-main/70"
                                            />
                                        </div>
                                    ) : (
                                        // </div>
                                        <EventsChat
                                            kiqsSelected={kiqsSelected}
                                            eventQuestions={eventQuestions}
                                            arrayRes={arrayRes}
                                            handleQuestionSelect={handleQuestionSelect}
                                            selectedQuestion={selectedQuestion}
                                            questionResponses={questionResponses}
                                        />
                                    )}
                                </div>
                                <div className="font-semibold text-gray-600 whitespace-normal text-justify p-2  border-t-2 border-main border-opacity-30">
                                    {dataEvent.description}
                                </div>
                                <div className="w-full flex sm:flex-col">
                                    <div className="w-1/2 sm:flex-col md:flex-col pb-3 border-main border-opacity-30">
                                        <p className="font-bold">Agents:</p>
                                        <ol className="flex flex-row space-x-32 sm:flex-col md:flex-col sm:space-x-0 md:space-x-0">
                                            {dataEvent.groups.map((group, groupIndex) => {
                                                return (
                                                    <div key={groupIndex}>
                                                        <p className="font-semibold text-main space-x-9 md:flex-col sm:flex-col whitespace-nowrap">
                                                            Group: {group.group_name}
                                                        </p>
                                                        {group.users &&
                                                            group.users.map((user, userIndex) => {
                                                                return <li>{user.fullname}</li>
                                                            })}
                                                    </div>
                                                )
                                            })}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SpacesEvents
