import React from 'react'
import { NavLink } from 'react-router-dom'
import { BiSolidLike, BiSolidDislike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import PDFIcon from '../../assets/pdf.png'
import ResponseMessage from './FilteredReplies'

const ResponseMessageChat = ({
    response,
    showReply,
    toggleReply,
    setSelectedImage,
    makeUrlsClickable,
    selectedResponse,
    setSelectedResponse,
    showResponse,
    toggleResponse,
}) => {
    const renderMessageStatus = () => {
        switch (response?.status) {
            case 0:
                return 'Message is deleted by User'
            case 10:
                return 'Message is deleted by Company Admin'
            case 20:
                return 'Message is deleted by Super Admin'
            default:
                return null
        }
    }

    return (
        <div className="cursor-pointer text-sm border-main p-2" key={response.id}>
            <span className="text-sm font-bold">{response?.user?.fullname}</span>

            {response.status === 2 || response.status === 11 || response.status === 21 ? (
                <div className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-start text-left bg-gray-300 bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                    {!showResponse && <span>Message is hidden</span>}
                    <button onClick={toggleResponse} className="">
                        {showResponse ? (
                            <p className="text-main text-left underline">Hide</p>
                        ) : (
                            <p className="text-main underline text-left">View</p>
                        )}
                    </button>
                    {showResponse && (
                        <>
                            {/* Show the hidden message */}
                            {response.answer &&
                                makeUrlsClickable(response?.answer).map((part, index) => (
                                    <React.Fragment key={index}>{part}</React.Fragment>
                                ))}

                            {/* Display total likes and dislikes */}
                            <div className="w-full flex gap-4 justify-between mt-2">
                                <div className="flex gap-2">
                                    <div className="flex">
                                        <span className="text-main">
                                            <BiSolidLike />
                                        </span>
                                        <span>{response.total_likes}</span>
                                    </div>
                                    <div className="flex">
                                        <span className="text-grey">
                                            <BiSolidDislike />
                                        </span>
                                        <span>{response.total_dislikes}</span>
                                    </div>
                                </div>
                                <div>
                                    <span>{response.created_at}</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : response?.status !== 0 && response?.status !== 10 && response?.status !== 20 ? (
                <div className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-start text-left bg-gray-300 bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                    <p
                        className="text-sm w-full text-justify break-words"
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {response.answer &&
                            makeUrlsClickable(response.answer).map((part, index) => (
                                <React.Fragment key={index}>{part}</React.Fragment>
                            ))}
                    </p>

                    {response?.attachments && response?.attachments?.length > 0 && (
                        <React.Fragment>
                            {response.attachments.filter(
                                (attachment) => attachment.type === 'image'
                            )?.length > 1 ? (
                                <div className="grid grid-cols-4 gap-1">
                                    {response.attachments
                                        .filter((attachment) => attachment.type === 'image')
                                        .map((attachment) => (
                                            <div
                                                className="flex items-start justify-start w-full"
                                                key={attachment.id}
                                            >
                                                <img
                                                    className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                    src={attachment.filename}
                                                    alt="Attachment"
                                                    onClick={() =>
                                                        setSelectedImage(attachment.filename)
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                response.attachments
                                    .filter((attachment) => attachment.type === 'image')
                                    .map((attachment) => (
                                        <div
                                            className="flex items-start justify-start w-full"
                                            key={attachment.id}
                                        >
                                            <img
                                                className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                src={attachment.filename}
                                                alt="Attachment"
                                                onClick={() =>
                                                    setSelectedImage(attachment.filename)
                                                }
                                            />
                                        </div>
                                    ))
                            )}

                            <div>
                                {response.attachments
                                    .filter((attachment) => attachment.type !== 'image')
                                    .map((attachment) => (
                                        <div
                                            className="flex items-start justify-start w-full"
                                            key={attachment.id}
                                        >
                                            {attachment.type === 'file' && (
                                                <NavLink
                                                    to={attachment.filename}
                                                    target="__blank"
                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                >
                                                    <img
                                                        className="w-8"
                                                        src={PDFIcon}
                                                        alt="TEXT_FILE"
                                                    />
                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                        {attachment.filename}
                                                    </p>
                                                </NavLink>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </React.Fragment>
                    )}

                    <div className="w-full flex gap-4 justify-between">
                        {response.type === 0 && (
                            <div className="flex gap-2">
                                <div className="flex">
                                    <span className="text-main">
                                        <BiSolidLike />
                                    </span>
                                    <span>{response.total_likes}</span>
                                </div>
                                <div className="flex">
                                    <span className="text-grey">
                                        <BiSolidDislike />
                                    </span>
                                    <span>{response.total_dislikes}</span>
                                </div>
                            </div>
                        )}
                        <div>
                            <span>{response.created_at}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-xs text-red-500">{renderMessageStatus(response.status)}</div>
            )}

            {/* Nested replies */}
            <div className="w-full flex flex-col gap-2">
                <div className="px-10 flex gap-2">
                    {response.responses?.length > 0 && (
                        <div className="flex gap-2">
                            <span className="rotate-90 text-base justify-center items-center">
                                <MdOutlineSubdirectoryArrowLeft />
                            </span>
                            <span
                                onClick={() =>
                                    setSelectedResponse(
                                        selectedResponse === response ? null : response
                                    )
                                }
                                className="cursor-pointer pt-2"
                            >
                                {selectedResponse === response ? 'Hide' : 'Show'} Replies
                            </span>
                        </div>
                    )}
                </div>

                {selectedResponse === response && response.responses?.length > 0 && (
                    <React.Fragment>
                        <div className="flex flex-col w-full ml-5 px-6 animate_fade_up mb-2">
                            {response.responses.map((reply) => (
                                <ResponseMessage
                                    key={reply.id}
                                    response={reply}
                                    showReply={showReply}
                                    toggleReply={toggleReply}
                                    setSelectedImage={setSelectedImage}
                                    makeUrlsClickable={makeUrlsClickable}
                                    selectedResponse={selectedResponse}
                                    setSelectedResponse={setSelectedResponse}
                                />
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ResponseMessageChat
