import React from 'react'
import { NavLink } from 'react-router-dom'
import { BiSolidLike, BiSolidDislike } from 'react-icons/bi'
import PDFIcon from '../../assets/pdf.png'

const ResponseMessage = ({
    response,
    showReply,
    toggleReply,
    setSelectedImage,
    makeUrlsClickable,
}) => {
    return (
        <>
            <span className="text-xs font-bold">{response?.user?.fullname}</span>
            <div className="text-xs flex flex-col w-fit max-w-[75%] gap-3 bg-main bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm">
                {response?.status === 0 ? (
                    'Message is deleted by User'
                ) : response?.status === 10 ? (
                    'Message is deleted by Company Admin'
                ) : response?.status === 20 ? (
                    'Message is deleted by Super Admin'
                ) : response?.status === 2 || response?.status === 11 || response?.status === 21 ? (
                    <div className="flex flex-col">
                        {!showReply[response.id] && <span>Message is hidden</span>}
                        <button onClick={() => toggleReply(response.id)} className="ml-2">
                            {showReply[response.id] ? (
                                <p className="text-main text-left underline">Hide</p>
                            ) : (
                                <p className="text-main underline text-left">View</p>
                            )}
                        </button>
                        {showReply[response.id] && (
                            <>
                                <p
                                    className="text-sm text-justify break-words"
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {response.answer &&
                                        makeUrlsClickable(response.answer).map((part, index) => (
                                            <React.Fragment key={index}>{part}</React.Fragment>
                                        ))}
                                </p>
                                {response?.attachments?.length > 0 && (
                                    <React.Fragment>
                                        {response.attachments.filter(
                                            (attachment) => attachment.type === 'image'
                                        )?.length > 1 ? (
                                            <div className="grid grid-cols-4 gap-1">
                                                {response.attachments
                                                    .filter(
                                                        (attachment) => attachment.type === 'image'
                                                    )
                                                    .map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-full"
                                                            key={attachment.id}
                                                        >
                                                            <img
                                                                className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                src={attachment.filename}
                                                                alt="Attachment"
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        attachment.filename
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : (
                                            response.attachments
                                                .filter((attachment) => attachment.type === 'image')
                                                .map((attachment) => (
                                                    <div
                                                        className="flex items-start justify-start w-full"
                                                        key={attachment.id}
                                                    >
                                                        <img
                                                            className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                            src={attachment.filename}
                                                            alt="Attachment"
                                                            onClick={() =>
                                                                setSelectedImage(
                                                                    attachment.filename
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ))
                                        )}
                                        <div>
                                            {response.attachments
                                                .filter((attachment) => attachment.type !== 'image')
                                                .map((attachment) => (
                                                    <div
                                                        className="flex items-start justify-start w-full"
                                                        key={attachment.id}
                                                    >
                                                        {attachment.type === 'file' && (
                                                            <NavLink
                                                                to={attachment.filename}
                                                                target="__blank"
                                                                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                            >
                                                                <img
                                                                    className="w-8"
                                                                    src={PDFIcon}
                                                                    alt="TEXT_FILE"
                                                                />
                                                                <p className="text-left text-xs break-all truncate-multiline-2">
                                                                    {attachment.filename}
                                                                </p>
                                                            </NavLink>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="w-full flex gap-4 justify-between">
                                    <div className="flex gap-2">
                                        <div className="flex">
                                            <span className="text-main">
                                                <BiSolidLike />
                                            </span>
                                            <span>{response.total_likes}</span>
                                        </div>
                                        <div className="flex">
                                            <span className="text-grey">
                                                <BiSolidDislike />
                                            </span>
                                            <span>{response.total_dislikes}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span>{response.created_at}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        <p
                            className="text-sm text-justify break-words"
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {response.answer &&
                                makeUrlsClickable(response.answer).map((part, index) => (
                                    <React.Fragment key={index}>{part}</React.Fragment>
                                ))}
                        </p>
                        {response?.attachments?.length > 0 && (
                            <React.Fragment>
                                {response.attachments.filter(
                                    (attachment) => attachment.type === 'image'
                                )?.length > 1 ? (
                                    <div className="grid grid-cols-4 gap-1">
                                        {response.attachments
                                            .filter((attachment) => attachment.type === 'image')
                                            .map((attachment) => (
                                                <div
                                                    className="flex items-start justify-start w-full"
                                                    key={attachment.id}
                                                >
                                                    <img
                                                        className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                        src={attachment.filename}
                                                        alt="Attachment"
                                                        onClick={() =>
                                                            setSelectedImage(attachment.filename)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    response.attachments
                                        .filter((attachment) => attachment.type === 'image')
                                        .map((attachment) => (
                                            <div
                                                className="flex items-start justify-start w-full"
                                                key={attachment.id}
                                            >
                                                <img
                                                    className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                    src={attachment.filename}
                                                    alt="Attachment"
                                                    onClick={() =>
                                                        setSelectedImage(attachment.filename)
                                                    }
                                                />
                                            </div>
                                        ))
                                )}
                                <div>
                                    {response.attachments
                                        .filter((attachment) => attachment.type !== 'image')
                                        .map((attachment) => (
                                            <div
                                                className="flex items-start justify-start w-full"
                                                key={attachment.id}
                                            >
                                                {attachment.type === 'file' && (
                                                    <NavLink
                                                        to={attachment.filename}
                                                        target="__blank"
                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                    >
                                                        <img
                                                            className="w-8"
                                                            src={PDFIcon}
                                                            alt="TEXT_FILE"
                                                        />
                                                        <p className="text-left text-xs break-all truncate-multiline-2">
                                                            {attachment.filename}
                                                        </p>
                                                    </NavLink>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </React.Fragment>
                        )}
                        <div className="w-full flex gap-4 justify-between">
                            <div>
                                <span>Date: {response.created_at}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ResponseMessage
