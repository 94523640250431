import ImageView from 'components/ImageView'
import React, { useEffect, useRef, useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../../assets/pdf.png'

export const UserResponse = ({ data, onClose }) => {
    const [selectedImage, setSelectedImage] = useState()

    const containerRef = useRef()

    useEffect(() => {
        // Scroll to the bottom of the container when the component mounts
        containerRef.current.scrollTop = containerRef.current.scrollHeight
    }, [])
    return (
        <div className=" w-full h-full overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className=" w-2/6 sm:w-4/6 bg-white h-[80%] flex flex-col justify-start items-start rounded-md shadow-md custom-scroll">
                <div className="flex leading-3 justify-between items-start border-l-4 border-main transition-all duration-300 w-full bg-user text-sm rounded-t-md bg-no-repeat bg-right bg-contain bg-[#fefefe] h-[80px] shadow-md font-semibold text-grey text-left cursor-default">
                    <div className=" px-5 flex flex-col justify-center h-full">
                        <p className="text-2xl">{data.fullname}</p>
                        <p className="text-md">
                            Responses Count -{' '}
                            <span className="text-main">{data.responses?.length}</span>
                        </p>
                    </div>
                    <span className="p-2 rounded-full  mt-2 mr-2 bg-opacity-75 bg-white">
                        <button
                            onClick={onClose}
                            className=" transition-all duration-300 text-lg font-bold text-main  hover:scale-110"
                        >
                            <IoCloseCircleOutline />
                        </button>
                    </span>
                </div>
                <div className="w-full h-full overflow-y-auto p-4 ">
                    {data.responses &&
                        data.responses.map((responses) => (
                            <div
                                className="flex flex-col w-full items-start justify-start "
                                key={responses.id}
                            >
                                <span className="text-xs font-bold mb-2">
                                    {responses?.user?.fullname}
                                </span>
                                <span className="text-xs gap-2 flex flex-col w-fit max-w-[80%] justify-start items-start text-left bg-[#a5a5a5] bg-opacity-30 py-2 pl-2 pr-4 rounded-tl-md rounded-r-md shadow-sm">
                                    {responses.answer}
                                    {responses.attachments && responses.attachments?.length > 0 && (
                                        <React.Fragment>
                                            {responses.attachments.map((attachment) => (
                                                <div
                                                    className="flex items-start justify-start w-full"
                                                    key={attachment.id}
                                                >
                                                    {attachment.type === 'image' ? (
                                                        <img
                                                            className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                            src={attachment.filename}
                                                            alt="Attachment"
                                                            onClick={() =>
                                                                setSelectedImage(
                                                                    attachment.filename
                                                                )
                                                            }
                                                            // className="h-[150px]"
                                                        />
                                                    ) : attachment.type === 'file' ? (
                                                        <NavLink
                                                            to={attachment.filename}
                                                            target="__blank"
                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                        >
                                                            <img
                                                                className="w-8"
                                                                src={PDFIcon}
                                                                alt="TEXT_FILE"
                                                            />
                                                            <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                {attachment.filename}
                                                            </p>
                                                        </NavLink>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    )}
                                    <div className="w-full flex gap-4 justify-between">
                                        {responses.type === 0 && (
                                            <div className="flex gap-2">
                                                <div className="flex">
                                                    <span className="text-main">
                                                        <BiSolidLike />
                                                    </span>
                                                    <span>{responses.total_likes}</span>
                                                </div>
                                                <div className="flex">
                                                    <span className="text-grey">
                                                        <BiSolidDislike />
                                                    </span>
                                                    <span>{responses.total_dislikes}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <span>{responses.created_at}</span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
