import React, { useEffect, useMemo, useRef, useState } from 'react'
import Papa from 'papaparse'
import DataTable from 'react-data-table-component'
import { TbTableExport } from 'react-icons/tb'
import { useQuery } from 'react-query'
import { getContributorsApi } from '../../services/request/Contributors'
import MainNav from 'components/MainNav'
import { LoadingComponent } from 'components/LoadingComponent'
import { Checkbox } from 'components/Checkbox'
import { FaFilter } from 'react-icons/fa'
import { BiChevronDown } from 'react-icons/bi'
import { IoCloseOutline } from 'react-icons/io5'
import ExportContributors from 'components/Exports/ExportContributors'
import { MdOutlineFileDownload } from 'react-icons/md'

export const Contributors = () => {
    const [search, setSearch] = useState('')
    const { data, isLoading } = useQuery(['getContributorsApi'], getContributorsApi)
    const { result } = useMemo(() => data ?? [], [data])
    const [filteredData, setFilteredData] = useState()

    const [toggle, setToggle] = useState(false)
    const checkboxRef = useRef(null)
    const [nameSearchable, setNameSearchable] = useState(true)
    const [emailSearchable, setEmailSearchable] = useState(true)
    const [responseSearchable, setResponseSearchable] = useState(true)
    const [companySearchable, setCompanySearchable] = useState(true)
    const [groupSearchable, setGroupSearchable] = useState(true)
    const [isDownload, setIsDownload] = useState(false)

    const columns = [
        {
            name: 'Name',
            selector: (row) => (nameSearchable ? row.fullname : null),
            cell: (row) => row.fullname,
            sortable: true,
            sortFunction: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
            name: 'Email',
            selector: (row) => (emailSearchable ? row.email : null),
            cell: (row) => (
                <a href={`mailto:${row.email}`} className="text-blue-500 hover:underline">
                    {row.email}
                </a>
            ),
        },
        {
            name: 'Responses Count',
            selector: (row) => (responseSearchable ? row.responses_count : null),
            cell: (row) => row.responses_count,
            center: true,
            sortable: true,
        },

        {
            name: 'Company',
            selector: (row) =>
                companySearchable ? row.companybelong && row.companybelong.firstname : null,
            cell: (row) => {
                const firstname = row.companybelong && row.companybelong.firstname
                return <span>{firstname}</span>
            },
            sortable: true,
        },

        {
            name: 'Groups',
            selector: 'groups',
            sortable: false,

            cell: (row) => (
                <div className="flex gap-2 items-left my-2 md:flex-col sm:flex-col xs:flex-col">
                    {row.groups &&
                        row.groups
                            .slice()
                            .sort((a, b) => a.group_name.localeCompare(b.group_name))
                            .map((group, index) => (
                                <p
                                    key={index}
                                    className="w-fit px-2 py-1 border-[.6px] border-main bg-main bg-opacity-40 rounded-sm"
                                >
                                    {group.group_name} {/* Access the 'group_name' property */}
                                </p>
                            ))}
                </div>
            ),
        },
    ]

    const handleSearchChange = (value) => {
        setSearch(value)

        const newFilteredData = result?.filter((user) => {
            const isSearchMatch =
                value.trim() === '' ||
                columns.some((column) => {
                    if (typeof column.selector === 'function') {
                        const selectedValue = column.selector(user)
                        if (typeof selectedValue === 'string') {
                            return selectedValue.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                    return false
                })

            const isGroupSearchMatch = groupSearchable
                ? value.trim() === '' ||
                  (user.groups &&
                      user.groups
                          .map((group) => group.group_name.toLowerCase())
                          .some((groupName) => groupName.includes(value.toLowerCase())))
                : null

            return isSearchMatch || isGroupSearchMatch
        })

        setFilteredData(newFilteredData)
    }
    const exportData = (data, fileName) => {
        const transformedData = data.map((row) => ({
            ...row,
            companybelong: row.companybelong && row.companybelong.firstname,
            groups: row.groups && row.groups.map((group) => group.group_name).join(', '),
        }))

        const csv = Papa.unparse(transformedData)

        const exportedDataBlob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
        })

        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(exportedDataBlob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const exportAllData = () => {
        // Export data when filteredData is defined
        if (filteredData) {
            exportData(filteredData, 'all_data.csv')
        } else {
            console.error('filteredData is undefined')
        }
    }

    const exportFilteredData = () => {
        // Export data when filteredData is defined
        if (filteredData) {
            exportData(filteredData, 'filtered_data.csv')
        } else {
            console.error('filteredData is undefined')
        }
    }

    useEffect(() => {
        // Call handleSearchChange whenever result or search changes
        handleSearchChange(search)
    }, [search, result])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggle(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className="flex flex-col w-full h-fit overflow-x-hidden">
            {isDownload && (
                <div className=" w-[50%] fixed h-full right-0 top-0 bottom-0 z-50 overflow-y-auto bg-white animate_move_to_Left shadow-md">
                    <ExportContributors
                        data={filteredData ?? result}
                        onClose={() => setIsDownload(false)}
                    />
                </div>
            )}
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex  gap-5 py-5 mb-10">
                <div className="w-full h-fit rounded-lg custom-scroll flex flex-col gap-4">
                    <div className="w-full px-5">
                        <MainNav />
                    </div>

                    <div className="w-full h-fit flex justify-between  px-5 sm:flex-col items-center">
                        <p className="font-bold sm:pb-3 text-2xl">List of Contributors</p>
                        <div className="flex w-1/2 items-end justify-end h-full gap-2 sm:flex-col">
                            <div className="flex w-full gap-1 justify-center items-center ">
                                <div className="w-full h-fit flex ">
                                    <input
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        type="text"
                                        value={search}
                                        className="border border-gray-400 rounded-md placeholder:text-sm pl-3 p-1 flex grow bg-white bg-opacity-60 outline-none"
                                        placeholder="Search User..."
                                    />

                                    {search?.length > 0 && (
                                        <div
                                            onClick={() => handleSearchChange('')}
                                            className="w-fit px-4 py-2 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                        >
                                            <IoCloseOutline />
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="flex flex-col justify-start items-end "
                                    ref={checkboxRef}
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex border border-gray-400 rounded-md py-2 px-4 bg-white shadow-inner text-main bg-opacity-50"
                                    >
                                        {' '}
                                        <div className="flex">
                                            <FaFilter /> <BiChevronDown />
                                        </div>
                                    </div>
                                    {toggle && (
                                        <div className="animate_fade_up z-20 absolute mt-9 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col border border-gray-400 rounded-md h-fit pt-2 pb-4 px-4 bg-white shadow-inner text-main">
                                            <p className="text-sm">Filter by:</p>
                                            <Checkbox
                                                key="Name"
                                                name="Name"
                                                title="Name"
                                                isChecked={nameSearchable}
                                                setValue={() => setNameSearchable(!nameSearchable)}
                                            />
                                            <Checkbox
                                                key="Email"
                                                name="Email"
                                                title="Email"
                                                isChecked={emailSearchable}
                                                setValue={() =>
                                                    setEmailSearchable(!emailSearchable)
                                                }
                                            />
                                            {/* <Checkbox
                                            key="Response Count"
                                            name="Response Count"
                                            title="Response Count"
                                            isChecked={responseSearchable}
                                            setValue={() =>
                                                setResponseSearchable(!responseSearchable)
                                            }
                                        /> */}
                                            <Checkbox
                                                key="Company"
                                                name="Company"
                                                title="Company"
                                                isChecked={companySearchable}
                                                setValue={() =>
                                                    setCompanySearchable(!companySearchable)
                                                }
                                            />
                                            <Checkbox
                                                key="Groups"
                                                name="Groups"
                                                title="Groups"
                                                isChecked={groupSearchable}
                                                setValue={() =>
                                                    setGroupSearchable(!groupSearchable)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex  gap-2 items-center ">
                                <button
                                    className={` text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap`}
                                    onClick={() => setIsDownload(true)}
                                >
                                    <span className="text-sm"> PDF</span> <MdOutlineFileDownload />
                                </button>
                                {search?.length === 0 && (
                                    <button
                                        className="text-sm bg-main px-3 py-2 rounded-md flex items-center  text-white whitespace-nowrap"
                                        onClick={exportAllData}
                                    >
                                        CSV{' '}
                                        <span className="text-base ml-2">
                                            <TbTableExport />
                                        </span>{' '}
                                    </button>
                                )}
                                <button
                                    className={`${
                                        search?.length === 0 ? 'hidden' : ''
                                    } text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap`}
                                    disabled={search?.length === 0}
                                    onClick={exportFilteredData}
                                >
                                    <TbTableExport />{' '}
                                    <span className="text-sm">Export Filtered</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-fit custom-header px-4 flex gap-0 sm:flex-col space-x-4 sm:space-x-0 sm:space-y-4 rounded-md overflow-hidden ">
                        <div className=" w-full grow rounded-md overflow-hidden">
                            <DataTable
                                progressComponent={<LoadingComponent title={'Contributors'} />}
                                progressPending={isLoading}
                                columns={columns}
                                data={filteredData ? filteredData : result}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="900px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
