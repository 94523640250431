import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { GetAccessTokenApi, GetUserInfoApi, GetUserTokenFromNexusApi } from 'services/request/Auth'
import { useAtom } from 'jotai'
import { authAtom } from 'store/authAtom'
import NBLogo from '../assets/NexusBrain.png'
import { jwtDecode } from 'jwt-decode'

export const OAuthConfimation = () => {
    const [auth, setAuth] = useAtom(authAtom)
    const token = localStorage.getItem('__auth_nexus') ?? ''
    const [params, setParams] = useSearchParams()
    const code = params.get('code') ?? ''

    useQuery(['getAccessToken'], () => GetAccessTokenApi(code), {
        onSuccess: (data) => {
            localStorage.setItem('__auth_nexus', JSON.stringify(data.data))
            window.location.reload()
        },
        onError: () => {
            if (token) {
                localStorage.clear()
                window.location.replace('/')
            }
        },
        enabled: token === '',
    })

    const { data: userInfo } = useQuery(['GetUserInfoApi'], () => GetUserInfoApi(), {
        onSuccess: () => {
            console.log('success')
        },
        onError: () => {
            console.log('error')
        },
    })

    const { data: responseToken } = useQuery(
        ['GetTokenApi'],
        () => GetUserTokenFromNexusApi(userInfo?.data),
        {
            onSuccess: (data) => {
                const { token } = data.data
                setAuth(token)
                window.location.reload()
            },
            onError: (e) => {
                console.log(e)
            },
            enabled: !!userInfo?.data,
        }
    )

    return (
        <div className="h-screen w-screen flex justify-center mt-[10%]">
            <div className="h-[30%] w-[60%] border flex justify-center p-20 rounded-lg">
                <div className="w-full h-full flex flex-col items-center animate-pulse">
                    <img src={NBLogo} alt="" className="w-[50px] h-[50px]" />
                    <p className="text-xl">Authenticating...</p>
                </div>
            </div>
        </div>
    )
}
